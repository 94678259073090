import { Box, Image } from '@chakra-ui/react';
import gradientAvatar from 'gradient-avatar';
import React, {useEffect, useState} from 'react';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';

import config from 'configs/app';
import { randomIntFromSeed } from 'lib/randomIntFromSeed';
import IdenticonGithub from 'ui/shared/IdenticonGithub';
import makeBlockie from 'ethereum-blockies-base64';

interface IconProps {
  hash: string;
  size: number;
}

const Icon = (props: IconProps): JSX.Element => {
  switch (config.UI.views.address.identiconType) {
    case 'github': {
      return <IdenticonGithub size={ props.size } seed={ props.hash }/>;
    }

    case 'blockie': {
      const blockie = makeBlockie(props.hash as `0x${string}`)
      if (!blockie) {
        return <div>Loading...</div>; // or some other placeholder
      }

      // eslint-disable-next-line react/display-name
      return (
          <Image
              src={blockie}
              alt={`Identicon for ${props.hash}`}
          />
      );
    }

    case 'jazzicon': {
      // eslint-disable-next-line react/display-name
      const seed = randomIntFromSeed(props.hash) * 10000;
      return (
        <Jazzicon
          diameter={ props.size }
          seed={ seed }
        />
      );
    }

    case 'gradient_avatar': {
      // eslint-disable-next-line react/display-name
      const svg = gradientAvatar(props.hash, props.size);
      return <div dangerouslySetInnerHTML={{ __html: svg }}/>;
    }

    default:
      return <div/>;
  }
};

type Props = IconProps;

const AddressIdenticon = ({ size, hash }: Props) => {

  return (
    <Box boxSize={ `${ size }px` } borderRadius="full" overflow="hidden">
      <Icon size={ size } hash={ hash }/>
    </Box>
  );
};

export default React.memo(AddressIdenticon);
