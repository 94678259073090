import type { LinkProps, FlexProps } from '@chakra-ui/react';
import { Flex, Link } from '@chakra-ui/react';
import type { LegacyRef } from 'react';
import React from 'react';
import { Link as HashLink } from 'react-router-dom';

// NOTE! use this component only for links to pages that are completely implemented in new UI
const LinkInternal = ({ isLoading, ...props }: LinkProps & { isLoading?: boolean }, ref: LegacyRef<HTMLAnchorElement>) => {
  if (isLoading) {
    return <Flex alignItems="center" { ...props as FlexProps }>{ props.children }</Flex>;
  }
  if (!props.href) {
    return <Link { ...props } ref={ ref }/>;
  }

  return (
    <HashLink to={ props.href } style={{ color: 'rgb(30,150,230)', textDecoration: 'underline'}}>
      {/* <Link { ...props } ref={ ref }/> */}
      {props.children ?? ''}
    </HashLink>
  );
};

export default React.memo(React.forwardRef(LinkInternal));
