export default function getApiVersionUrl(version: string | undefined): string | undefined {
  if (!version) {
    return;
  }

  const [ tag, commit ] = version.split('.+commit.');


  const gitUrl = 'https://gitlab.com/pulsechaincom/pulsechain-explorer-server'

  // if (commit) {
  //   return `https://github.com/blockscout/blockscout/commit/${ commit }`;
  // }

  // return `https://github.com/blockscout/blockscout/tree/${ tag }`;
  if (commit) {
    return `${ gitUrl }/commit/${ commit }`;
  }

  return `${ gitUrl }/tree/${ tag }`;
  //https://gitlab.com/pulsechaincom/pulsechain-explorer-server/
}
